import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import { loadingIndicator } from "../components/common/loading";
import Settings from "../pages/Settings/Settings";

const SiteBCM = lazy(() => import("../pages/Site/SiteBCM"));
const Dashboard = lazy(() => import("../pages/Site/Dashboard/Dashboard"));
const SiteLayout = lazy(() => import("../pages/Site/SiteLayout"));
const SiteGuard = lazy(() => import("../pages/Site/SiteGuard"));

export default function SiteOwnerRoutes() {
  return (
    <Suspense fallback={loadingIndicator}>
      <Routes>
        {<Route path="/" element={<SiteLayout />} />}
        <Route
          path="/site/:id"
          element={
            <SiteGuard>
              <Dashboard />
            </SiteGuard>
          }
        />
        <Route
          path="/business-continuity-management/:id"
          element={
            <SiteGuard>
              <SiteBCM />
            </SiteGuard>
          }
        />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </Suspense>
  );
}
